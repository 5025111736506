import React from "react";
import "../css/about.css";
import Fade from "react-reveal/Fade";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { Button } from "@mantine/core";

function JoinUs() {
	return (
		<ParallaxProvider>
			<div className="about" id="JoinUs">
				<div className="descripiton-left">
					<Fade bottom className="description-text">
						<h1>BUSINESS PARTNERSHIP</h1>
						<span style={{ fontSize: 16 }}>
							We focus on building long-term partnerships with organizations
							(farmers, aquaculturists, dive clubs, NGOs) that want to be
							catalysts for change and inspire people to save the reef with us
							and become sustainable food security actors.
						</span>
						<span style={{ fontSize: 16 }}>
							Together, we develop a mutually beneficial relationship with a
							customized storytelling strategy to expand our collective impact.
						</span>
					</Fade>

					<Button
						color="orange"
						style={{
							marginTop: 15,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "row",
							width: 60,
						}}
						onClick={() =>
							window.open("https://forms.gle/M2Dwap4JJhDm7ZRx9", "_blank")
						}
					>
						JOIN US{" "}
					</Button>
				</div>
				<div className="responsive-description">
					<h1 style={{ marginTop: 16 }}>BUSINESS PARTNERSHIP</h1>
					<span style={{ fontSize: 12, marginBottom: 10 }}>
						We focus on building long-term partnerships with organizations
						(farmers, aquaculturists, dive clubs, NGOs) that want to be
						catalysts for change and inspire people to save the reef with us and
						become sustainable food security actors.
					</span>
					<br />

					<span style={{ fontSize: 12 }}>
						Together, we develop a mutually beneficial relationship with a
						customized storytelling strategy to expand our collective impact.
					</span>
					<Button
						color="orange"
						style={{
							marginTop: 15,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "row",
							width: 60,
						}}
						onClick={() =>
							window.open("https://forms.gle/M2Dwap4JJhDm7ZRx9", "_blank")
						}
					>
						JOIN US{" "}
					</Button>
				</div>
				<div className="img-holder">
					<Parallax speed={2}>
						<img
							src="https://images.unsplash.com/photo-1470092306007-055b6797ca72?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80"
							alt=""
							style={{
								border: "solid 1.5px white ",
								height: 420,
								width: "auto",
								borderRadius: "8px",
							}}
						/>
					</Parallax>
				</div>
			</div>
		</ParallaxProvider>
	);
}

export default JoinUs;

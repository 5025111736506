import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../assets/logo.png";
import "../css/navbar.css";

function Navbar() {
	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);

	const closeMenu = () => setClick(false);

	return (
		<div className="header">
			<nav className="navbar">
				<img src={logo} alt="logo" style={{ width: 60, marginTop: 10 }} />
				<div className="hamburger" onClick={handleClick}>
					{click ? (
						<FaTimes size={30} style={{ color: "#ffffff" }} />
					) : (
						<FaBars size={30} style={{ color: "#ffffff" }} />
					)}
				</div>
				<ul className={click ? "nav-menu active" : "nav-menu"}>
					<li className="nav-item">
						<a href="#About" onClick={closeMenu}>
							About
						</a>
					</li>
					<li className="nav-item">
						<a href="#Our Product" onClick={closeMenu}>
							Our Product
						</a>
					</li>
					<li className="nav-item">
						<a
							href="https://akomafront.rightnow-by-brenco.com/register"
							onClick={closeMenu}
						>
							SignUp
						</a>
					</li>
					<li className="nav-item">
						<a
							href="https://akomafront.rightnow-by-brenco.com/login"
							onClick={closeMenu}
						>
							LogIn
						</a>
					</li>
				</ul>
			</nav>
		</div>
	);
}

export default Navbar;

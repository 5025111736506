import React from "react";
import {
	FaLinkedin,
	FaFacebookSquare,
	FaInstagram,
	FaMapMarkerAlt,
	FaEnvelope,
	FaYoutube,
} from "react-icons/fa";

function Footer() {
	const list = ["Home", "About", "Methods", "JoinUs"];
	return (
		<div className="footer">
			<div className="footer-navigation">
				<h4 style={{ fontSize: 16 }}>Navigate </h4>
				{list.map((e) => {
					return (
						<a
							href={`#${e}`}
							style={{
								cursor: "pointer",
								color: "white",
								fontSize: 12,
							}}
						>
							{e}
						</a>
					);
				})}
			</div>
			<div className="footer-contact">
				<h4 style={{ fontSize: 16 }}>Contact us </h4>

				<span>
					<FaEnvelope style={{ marginRight: "5", fontSize: 12 }} />
					yes@akoma.earth
				</span>
				<span style={{ textAlign: "left" }}>
					<FaMapMarkerAlt
						style={{ marginRight: "5", textAlign: "left", fontSize: 12 }}
					/>
					29 rue Souidani Boudjemaa, Cheraga{" "}
				</span>
			</div>

			<div className="social-media">
				<a href="https://www.linkedin.com/company/akoma-earth/">
					<FaLinkedin size={20} style={{ color: "#ffffff", margin: 2 }} />
				</a>
				<a href="https://www.facebook.com/profile.php?id=100090327021447">
					<FaFacebookSquare size={20} style={{ color: "#ffffff", margin: 2 }} />
				</a>
				<a href="https://www.instagram.com/akoma.earth">
					<FaInstagram size={20} style={{ color: "#ffffff", margin: 2 }} />
				</a>
				<a href="https://www.youtube.com/@AkomaEarth">
					<FaYoutube size={20} style={{ color: "#ffffff", margin: 2 }} />
				</a>
			</div>
		</div>
	);
}

export default Footer;

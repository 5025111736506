import React from "react";
import Hero from "../Components/hero";
import About from "../Components/about";
import Footer from "../Components/footer";
import Navbar from "../Components/navbar";
import ResponsiveContent from "../Components/responsive-content";
import Info from "../Components/info";
import Methods from "../Components/methods";
import JoinUs from "../Components/joinUs";

export default function LandingPage() {
	return (
		<div>
			<Navbar />
			<Hero />
			<ResponsiveContent />
			<About />
			<Methods />
			<JoinUs />
			<Info />
			<Footer />
		</div>
	);
}

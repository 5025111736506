import React from "react";
import { useNavigate } from "react-router";
// import { Button } from "@mantine/core";
import { Button, Space } from "antd";

function Hero() {
	let navigate = useNavigate();

	return (
		<div className="video-background" id="Home">
			<video
				src="https://gitlab.com/slimanirayene/akoma-files/-/raw/main/akoma_video_cut.mp4"
				autoPlay
				loop
				muted
			/>
			<div className="content">
				<h1> Akoma.Earth </h1>
				<p>What If You Can Reduce Your </p>
				<p>Carbon Footprint By Creating Life</p>
				<div className="buttons">
					<Button
						style={{
							backgroundColor: "orange",
							color: "white",
						}}
						type="primary"
						onClick={() =>
							window.open(
								"https://akomafront.rightnow-by-brenco.com/login",
								"_blank"
							)
						}
					>
						COMPUTE FOOTPRINT
					</Button>
					<Button
						style={{
							backgroundColor: "orange",
							color: "white",
						}}
						type="primary"
						onClick={() => {
							navigate("/FlightFootprint");
						}}
					>
						FLIGHT FOOTPRINT
					</Button>
					<Button
						style={{
							backgroundColor: "orange",
							color: "white",
						}}
						type="primary"
						onClick={() => {}}
					>
						KNOW MORE
					</Button>
				</div>
			</div>
		</div>
	);
}

export default Hero;

import "./App.css";
import React from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import LandingPage from "./Containers/LandingPage";
import { MantineProvider } from "@mantine/core";

import FlightFootprint from "./Containers/FlightFootprint";

function App() {
	return (
		<MantineProvider withGlobalStyles withNormalizeCSS>
			<div className="App">
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<LandingPage />} />
						<Route path="/FlightFootprint" element={<FlightFootprint />} />
					</Routes>
				</BrowserRouter>
			</div>
		</MantineProvider>
	);
}

export default App;

import React from "react";
import { Button } from "@mantine/core";
import "../App.css";
export default function ResponsiveContent() {
	return (
		<div className="responsive-content">
			<div className="debord">
				<h1> Akoma.Earth </h1>
				<p>What If You Can Reduce Your </p>
				<p>Carbon Footprint By Creating Life</p>
				<div className="buttons">
					<Button
						color="orange"
						style={{
							marginTop: 15,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "row",
						}}
					>
						COMPUTE FOOTPRINT
					</Button>
					<Button
						variant="outline"
						color="orange"
						style={{
							marginTop: 15,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						KNOW MORE
					</Button>
				</div>
			</div>
		</div>
	);
}

import React from "react";
import "../css/process.css";

export default function Methods() {
	return (
		<div className="process" id="Methods">
			<h1>How it works ?</h1>
			<div className="method">
				<div className="single">
					<h4>Compute</h4>
					<p>Your Carbon Footprint</p>
					<span>
						Calculate your carbon footprint with our intelligent algorithm to
						know what you have to do in less than 5mn.
					</span>
				</div>
				<div className="single">
					<h4>Choose</h4>
					<p>Your Reduction Action</p>
					<span>
						Choose the compensation action, or ask our algorithm to propose you
						a compensation action according to your budget.
					</span>
				</div>
				<div className="single">
					<h4>Balance</h4>
					<p>Your Carbon Credit</p>
					<span>
						According to the balance of your carbon credit after financing the
						offset action you can resell your positive credit on AKOMA.
					</span>
				</div>
			</div>
		</div>
	);
}

import React from "react";
import Footer from "../Components/footer";
import Navbar from "../Components/navbar";
import FlightFootprintCalc from "../Components/flightFootprintCalc";

export default function FlightFootprint() {
	return (
		<div>
			<Navbar />
			<FlightFootprintCalc />
			<div style={{ bottom: 0 }}>
				<Footer />
			</div>
		</div>
	);
}

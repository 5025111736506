import React from "react";
import { useState } from "react";
import { Grid, TextInput, Radio, NumberInput, Button, Text } from "@mantine/core";

function FlightFootprintCalc() {
    const [depart, setdepart] = useState('');
    const [destination, setDestination] = useState('');
    const [distance, setDistance] = useState(0);
    const [typeVol, setTypeVol] = useState('allerRetour');
    const [nbrPassagers, setNbrPassagers] = useState(0); // S in the formula
    const [classVol, setClassVol] = useState('economique');
    const [result, setResult] = useState(0);

    // facteurs de la formule
    let a;
    let b;
    let c;
    let S;
    let FCP = 0.82;
    let DC = 95;
    let FF;
    let PC;
    let FE = 3.15;
    let P = 0.54;
    let M = 2;
    let FA = 0.00038;
    let A = 11.68;

    // variables
    let dist = 0

    const initFacteur = () => {

        if (typeVol === 'allerRetour')
            dist = distance * 2
        else
            dist = distance

        if (dist < 1500) {
            a = 0;
            b = 2.714;
            c = 1166.52;
            FF = 0.93;
            S = 153.51
            if (classVol === 'economique')
                PC = 0.96
            if (classVol === 'buisness')
                PC = 1.26
            if (classVol === 'first')
                PC = 2.4
        }
        else if (dist > 1500) {
            a = 0.0001;
            b = 7.104;
            c = 5044.93;
            FF = 0.74;
            S = 280.21
            if (classVol === 'economique')
                PC = 0.8
            if (classVol === 'buisness')
                PC = 1.54
            if (classVol === 'first')
                PC = 2.4
        }


    }

    const CalcFoorprint = () => {
        if (distance > 0) {
            initFacteur();
            let fx = ((a * dist * dist) + (b * dist) + c) / (S * FCP)
            let E = fx * (1 - FF) * PC * (FE * M + P) + FA * dist + A
            setResult(E.toFixed(2))
        }
    }

    return (
        <div className="video-background" id="Home" >
            <video

                autoPlay
                loop
                muted
            />
            <div className="calc" style={{marginTop:"15px"}}>
                <h4> Flight Footprint </h4>
                <Grid>
                    <Grid.Col span={"content"} style={{ borderRight: "1px solid grey" }}>
                        <form className="calc-form">
                            {/* <TextInput
                                placeholder="Ex: ville, aéroport...."
                                label="Départ"
                                radius="md"
                                withAsterisk
                                value={depart}
                                onChange={(event) => setdepart(event.currentTarget.value)}
                            />
                            <TextInput
                                placeholder="Ex: ville, aéroport...."
                                label="Destination"
                                radius="md"
                                withAsterisk
                                value={destination}
                                onChange={(event) => setDestination(event.currentTarget.value)}
                            /> */}
                            <NumberInput
                                label="Distance (Km)"
                                min={1}
                                value={distance}
                                onChange={setDistance} />
                            <Radio.Group
                                value={typeVol}
                                onChange={setTypeVol}
                                label="Type de vol"
                                withAsterisk
                            >
                                <Radio value="allerRetour" label="Vol Aller-Retour" />
                                <Radio value="simple" label="Vol Simple" />
                            </Radio.Group>
                            <NumberInput
                                label="Nombre de passagers"
                                max={200}
                                min={0}
                                value={nbrPassagers}
                                onChange={setNbrPassagers} />
                            <Radio.Group
                                value={classVol}
                                onChange={setClassVol}
                                label="Classe du vol"
                                withAsterisk
                            >
                                <Radio value="economique" label="Classe économique" />
                                <Radio value="buisness" label="Classe affairs" />
                                <Radio value="first" label="Premiere classe" />
                            </Radio.Group>
                            <Button variant="outline" color="orange" radius="sm" onClick={CalcFoorprint}>
                                Claculez
                            </Button>
                        </form></Grid.Col>
                    <Grid.Col span={"content"}>
                        <Text>
                            footprint pour {distance}(km), {nbrPassagers} voyageurs :  {result} (T Co2)
                        </Text>
                    </Grid.Col>
                </Grid>

            </div>
        </div>
    );
}

export default FlightFootprintCalc;

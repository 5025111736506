import React from "react";
import "../css/footer.css";
import Carroussel from "./carroussel";

export default function Info() {
	const links = [
		"https://gitlab.com/slimanirayene/akoma-files/-/raw/main/streaming_package.png",
		"https://gitlab.com/slimanirayene/akoma-files/-/raw/main/videos_package.png",
		"https://gitlab.com/slimanirayene/akoma-files/-/raw/main/streaming_package.png",
	];
	const imgSrc =
		"https://images.unsplash.com/photo-1478365523567-02b9a88f9b8b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1576&q=80";
	return (
		<div
			style={{
				width: "100%",
				height: "100vh",
				overflow: "hidden",
				backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)),url(${imgSrc})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				color: "white",
				fontSize: 18,
				display: "flex",
				alignContent: "center",
				flexDirection: "column",
			}}
			className="infos"
			id="Our Product"
		>
			<Carroussel />
		</div>
	);
}

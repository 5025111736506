import React from "react";
import "../css/about.css";
import Fade from "react-reveal/Fade";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

function About() {
	return (
		<ParallaxProvider>
			<div className="about" id="About">
				<div className="img-holder">
					<Parallax speed={2}>
						<img
							src="https://images.unsplash.com/photo-1598335624134-5bceb5de202d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80"
							alt=""
							style={{
								border: "solid 1.5px white ",
								height: 400,
								width: "auto",
								opacity: 0.6,
								borderRadius: "8px",
							}}
						/>
					</Parallax>
				</div>

				<div className="descripiton">
					<Fade bottom className="description-text">
						<h1>ABOUT US</h1>
						<span style={{ fontSize: 15 }}>
							Welcome to Akoma, a startup dedicated to making a positive impact
							on the planet by selling carbon debt. Our mission is to empower
							individuals and businesses to take responsibility for their carbon
							footprint and help combat climate change.
						</span>
						<span style={{ fontSize: 15 }}>
							At Akoma, we believe that everyone has a role to play in creating
							a sustainable future. We are committed to providing a simple and
							effective way for individuals and businesses to offset their
							carbon emissions by purchasing carbon credits.
						</span>

						<span style={{ fontSize: 15 }}>
							{" "}
							Join us on this journey to a better future, one carbon credit at a
							time.
						</span>
					</Fade>
				</div>
				<div className="responsive-description">
					<h1>ABOUT US</h1>
					<span>
						Welcome to Akoma, a startup dedicated to making a positive impact on
						the planet by selling carbon debt. Our mission is to empower
						individuals and businesses to take responsibility for their carbon
						footprint and help combat climate change.
					</span>
					<span>
						At Akoma, we believe that everyone has a role to play in creating a
						sustainable future. We are committed to providing a simple and
						effective way for individuals and businesses to offset their carbon
						emissions by purchasing carbon credits.
					</span>

					<span style={{ fontSize: 10 }}>
						{" "}
						Join us on this journey to a better future, one carbon credit at a
						time.
					</span>
				</div>
			</div>
		</ParallaxProvider>
	);
}

export default About;

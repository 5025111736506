import { React, useEffect, useState } from "react";
import "../css/carroussel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Button, Modal, Form, Input, InputNumber } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { responsiveArray } from "antd/es/_util/responsiveObserver";

// const baseURL = 'http://localhost:8000/'
const baseURL = 'https://akomaapi.rightnow-by-brenco.com/'

export default function Carroussel() {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [paymentPlans, setPlans] = useState([]);
	const [selectedPlan, setPlan] = useState("");
	const [id, setId] = useState(0);
	const [price, setPrice] = useState(0);

	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 16 },
	};

	/* eslint-disable no-template-curly-in-string */
	const validateMessages = {
		required: "${label} is required!",
		types: {
			email: "${label} is not a valid email!",
			number: "${label} is not a valid number!",
		},
	};
	/* eslint-enable no-template-curly-in-string */

	const onFinish = async (values) => {
		console.log("values", values);
		try {
			const requestOptions = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					plan: id,
					email: values.user.email,
					password: values.user.password,
					name: values.user.name,
				}),
			};
			const response = await fetch(
				baseURL+"api/plan/user/add/",
				requestOptions
			);
			let data = response.json();
			data
				? handleCancel()
				: window.alert("something went wrong, try again ! ");
		} catch (e) {
			console.log(e);
		}
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	var settings = {
		dots: false,
		infinite: true,
		autoplay: true,
		speed: 900,
		autoplaySpeed: 4000,
		slidesToShow: 3,
		slidesToScroll: 1,
		initialSlide: 0,
		pauseOnHover: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const links = [
		"https://gitlab.com/slimanirayene/akoma-files/-/raw/main/WhatsApp_Image_2023-08-27_at_19.48.03.jpeg",
		"https://gitlab.com/slimanirayene/akoma-files/-/raw/main/WhatsApp_Image_2023-08-27_at_19.48.17.jpeg",
		"https://gitlab.com/slimanirayene/akoma-files/-/raw/main/WhatsApp_Image_2023-08-27_at_19.48.17__1_.jpeg",
	];

	const GetPlans = async () => {
		try {
			const response = await fetch(
				baseURL+"api/plan/list/all/"
			);
			const data = await response.json();
			setPlans(data);
			console.log("-----", data);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		(async () => {
			await GetPlans();
		})();
	}, []);

	console.log("state:----", paymentPlans);
	return (
		<div
			className="carroussel"
			style={{
				overflow: "hidden",
				width: "100%",
				height: "100vh",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<h1 style={{ margin: "30px 0 30px 20px", overflow: "hidden" }}>
				Our Product : Co2 Reducing Capacities
			</h1>
			<div className="holder" style={{ flexWrap: "wrap" }}>
				<Slider {...settings}>
					{paymentPlans.map((el, index) => (
						<div className="box" key={index}>
							<div
								className="background-img"
								style={{
									backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),url(${el.plan_image})`,
									width: "100%",
								}}
							>
								<div
									style={{
										width: "100%",
										height: "100%",
										position: "relative",
									}}
								>
									<Button
										style={{
											backgroundColor: "orange",
											color: "white",
											position: "absolute",
											bottom: 5,
											right: 30,
										}}
										type="primary"
										onClick={() => {
											showModal();
											setId(el.id);
											setPrice(el.price);
											setPlan(el.plan_name);
										}}
									>
										Take action
									</Button>
								</div>
							</div>
						</div>
					))}
				</Slider>
			</div>
			<Modal
				title="Fill in your info"
				open={isModalOpen}
				onCancel={handleCancel}
				footer={null}
				style={{ overflow: "hidden" }}
			>
				<span style={{ fontSize: 15 }}>
					You have chosen the {selectedPlan} plan, it costs {price}DA, fill in
					your info to get you started
				</span>
				<br />
				<br />
				<Form
					{...layout}
					name="nest-messages"
					onFinish={onFinish}
					style={{ maxWidth: 600 }}
					validateMessages={validateMessages}
				>
					<Form.Item
						name={["user", "name"]}
						label="Name"
						rules={[{ required: true }]}
						style={{ overflow: "hidden" }}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name={["user", "email"]}
						label="Email"
						rules={[{ type: "email" }]}
						style={{ overflow: "hidden" }}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name={["user", "password"]}
						label="Password"
						rules={[{ type: "password" }]}
						style={{ overflow: "hidden" }}
					>
						<Input.Password
							placeholder="input password"
							iconRender={(visible) =>
								visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
							}
						/>
					</Form.Item>

					<Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
						<Button
							type="primary"
							style={{
								backgroundColor: "orange",
								color: "white",
							}}
							htmlType="submit"
						>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
}
